import React from "react";
import {Link, useLocation} from "react-router-dom";
import pie from "../../assets/icons/pie-chart.svg";
import report from "../../assets/icons/pie-chart.svg";
import returnBack from "../../assets/icons/return-back.svg";
import settings from "../../assets/icons/settings.svg";
import switchIcon from "../../assets/icons/switch.svg"
import percentage from "../../assets/icons/percentage.svg";

const Sidebar: React.FC = () => {
    const location = useLocation();
    const isAdmin = localStorage.getItem("isAdmin") === "true";
    const isActive = (path: string) => location.pathname.startsWith(path) ? '!bg-secondary' : '';
    return (
        <nav>
            <ul className="mt-8 mx-8">
                <Link to={'/taksit'}>
                    <li className={`cursor-pointer transition-all duration-200 flex items-center gap-4 px-5 py-[13px] mb-4 rounded-lg hover:bg-background-default text-text-primary ${isActive('/taksit')}`}>
                        <img src={pie} alt=""/>
                        <span className="text-xl font-medium text-text-primary">Taksitə bölmə</span>
                    </li>
                </Link>
                <Link to={'/operations'}>
                    <li className={`cursor-pointer transition-all duration-200 flex items-center gap-4 px-5 py-[13px] mb-4 rounded-lg hover:bg-background-default text-text-primary ${isActive('/operations')}`}>
                        <img src={switchIcon} alt=""/>
                        <span className="text-xl font-medium text-text-primary">Son Əməliyyatlar</span>
                    </li>
                </Link>

                <Link to={'/refund'}>
                    <li className={`cursor-pointer transition-all duration-200 flex items-center gap-4 px-5 py-[13px] mb-4 rounded-lg hover:bg-background-default text-text-primary ${isActive('/refund')}`}>
                        <img src={returnBack} alt=""/>
                        <span className="text-xl font-medium text-text-primary">Geri qaytarılma</span>
                    </li>
                </Link>


                {isAdmin && <Link to={'/reports'}>
                    <li className={`cursor-pointer transition-all duration-200 flex items-center gap-4 px-5 py-[13px] mb-4 rounded-lg hover:bg-background-default text-text-primary ${isActive('/reports')}`}>
                        <img src={report} alt=""/>
                        <span className="text-xl font-medium text-text-primary">Hesabat</span>
                    </li>
                </Link>}

                {isAdmin && <Link to={'/rates'}>
                    <li className={`cursor-pointer transition-all duration-200 flex items-center gap-4 px-5 py-[13px] mb-4 rounded-lg hover:bg-background-default text-text-primary ${isActive('/rates')}`}>
                        <img src={percentage} alt=""/>
                        <span className="text-xl font-medium text-text-primary">Tariflər</span>
                    </li>
                </Link>}
                {isAdmin && <Link to={'/users'}>
                    <li className={`cursor-pointer transition-all duration-200 flex items-center gap-4 px-5 py-[13px] mb-4 rounded-lg hover:bg-background-default text-text-primary ${isActive('/users')}`}>
                        <img src={settings} alt=""/>
                        <span className="text-xl font-medium text-text-primary">Tənzimləmələr</span>
                    </li>
                </Link>}
            </ul>
        </nav>
    );
};

export default Sidebar;
