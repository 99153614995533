import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import CustomModal from "./CustomModal";
import success from "../assets/icons/ForgotSuccess.svg";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.text.primary,
        paddingTop: "30px",
        paddingBottom: "30px",
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        paddingTop: "30px",
        paddingBottom: "30px",
    },
}));

interface Header {
    label?: string;
    field: string;
    align?: 'right' | 'left' | 'center' | 'inherit' | 'justify';
}

interface RowData {
    [key: string]: React.ReactNode | string;
}

interface CustomizedTablesProps {
    name?: string;
    headers: Header[];
    rows: RowData[];
    notFoundComponent?: React.ReactNode;
}

const renderCellContent = (content: React.ReactNode) => {
    if (React.isValidElement(content)) {
        return content;
    }
    if (typeof content === 'string' || typeof content === 'number') {
        return content;
    }
    return null;
};

const CustomizedTables: React.FC<CustomizedTablesProps> = ({ name, headers, rows, notFoundComponent }) => {
    return (
        <>
            {
                name && (
                    <h1 className="my-[50px] font-semibold text-[20px] text-text-primary">{name ? name : ""}</h1>
                )
            }
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            {headers.map((header, index) => (
                                <TableCell key={index} align={header.align || 'left'}>
                                    {header.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.length > 0 ? (
                            rows.map((row, rowIndex) => (
                                <TableRow key={rowIndex}>
                                    {headers.map((header, cellIndex) => (
                                        <StyledTableCell key={cellIndex} align={header.align || 'left'}>
                                            {renderCellContent(row[header.field])}
                                        </StyledTableCell>
                                    ))}
                                </TableRow>
                            ))
                        ) : (
                            <TableRow sx={{backgroundColor: '#ffffff!important'}}>
                                <TableCell colSpan={headers.length} align="center">
                                    {notFoundComponent || 'No data found'}
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};

export default CustomizedTables;
