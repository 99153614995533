import React, {useEffect, useState } from "react";
import { TextField } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import error from "../assets/icons/error.svg";

interface SearchReportProps {
    handleOpenModal: () => void;
    selectedDateRange: string;
    setSelectedUuid: React.Dispatch<React.SetStateAction<number | null>>;
}

const SearchReport: React.FC<SearchReportProps> = ({ handleOpenModal, selectedDateRange, setSelectedUuid }) => {
    const formik = useFormik({
        initialValues: {
            date: selectedDateRange,
            uuid: '',
        },
        validationSchema: Yup.object({
            date: Yup.string(),
            uuid: Yup.string(),
        }),
        onSubmit: values => {
            console.log('Form submitted:', values);
        },
    });
    console.log(selectedDateRange, 'selectedDateRange')
    const [notFound, setNotFound] = useState<boolean>(false);
    const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
        handleOpenModal();
        event.target.blur();
    };

    useEffect(() => {
        formik.setFieldValue('date', selectedDateRange);
    }, [selectedDateRange])

    return (
        <div className="rounded-lg">
            <form onSubmit={formik.handleSubmit}>
                <div className="flex justify-between gap-6">
                    <div className="mb-4 w-full max-w-[312px]">
                        <TextField
                            fullWidth
                            id="date"
                            name="date"
                            placeholder="Tarixi daxil edin"
                            value={formik.values.date}
                            error={notFound}
                            onFocus={handleFocus}
                            onChange={formik.handleChange}
                        />
                    </div>
                    <div className="mb-4 w-full max-w-[312px]">
                        <TextField
                            fullWidth
                            id="uuid"
                            name="uuid"
                            placeholder="Unikal kodu daxil edin"
                            value={formik.values.uuid}
                            onChange={(event) => {
                                formik.handleChange(event)
                                setSelectedUuid(event.target.value as any)
                            }}
                            error={notFound}
                        />
                    </div>
                </div>
                {notFound && (
                    <p className="text-[#FF5F57] flex items-center gap-3">
                        <img src={error} alt="Error" />
                        <span>Qeyd olunan məlumatlara əsasən istifadəçi tapılmadı</span>
                    </p>
                )}
            </form>
        </div>
    );
};

export default SearchReport;
