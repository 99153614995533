import React, {useEffect, useState} from "react";
import avatar from '../../assets/icons/avatar.svg';
import arrow from '../../assets/icons/down-arrow.svg';
import logout from '../../assets/icons/logout.svg';

import {Button, Menu, MenuItem} from "@mui/material";
import {useNavigate} from "react-router-dom";
import { post } from "../../config/apiService";

const Header: React.FC = () => {
    const navigate = useNavigate()
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [username, setUsername] = useState<string | null>('');
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    useEffect(() => {
        const user = localStorage.getItem('username')
        setUsername(user)
    }, []);

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = async () => {
        try {
            setAnchorEl(null);
            localStorage.removeItem('accessToken');
            localStorage.removeItem('refreshToken');
            await post('/auth/logout', {})
            navigate('/login')
        }catch (e) {
            navigate('/login')
        }

    };

  return (
      <header className="p-4 bg-primary w-full flex justify-end">
          <Button className="flex gap-2" onClick={handleClick} style={{ display: 'flex', alignItems: 'center' }}>
              <img src={avatar} alt="User Avatar" />
              <span className="text-text-primary normal-case text-[14px] font-medium">{username}</span>
              <img src={arrow} alt=""/>
          </Button>
          <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
              }}
              transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
              }}
          >
              <MenuItem onClick={handleLogout}>
                  <div className="flex justify-start items center gap-2">
                      <img src={logout} alt=""/>
                      <span className="text-[#FF5F57]">Çıxış et</span>
                  </div>
              </MenuItem>
          </Menu>
      </header>
  );
};

export default Header;
