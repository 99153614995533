import React from "react";
// import { Button, useTheme } from "@mui/material";

const UiComponents: React.FC = () => {
  // const theme = useTheme();
  // const toggleDarkMode = () => {
  //   document.documentElement.classList.toggle("dark");
  // };
  return (
    <div>
      {/*<Button*/}
      {/*  onClick={toggleDarkMode}*/}
      {/*  variant="contained"*/}
      {/*  sx={{ bgcolor: theme.palette.accent.dark }}*/}
      {/*>*/}
      {/*  MUI Button*/}
      {/*</Button>*/}

      <div className="grid grid-cols-3 gap-6 mt-4">
        <div>
          <p className="bg-primary p-6 text-lg">primary</p>
          <p className="bg-primary-light p-6">primary l</p>
          <p className="bg-primary-dark p-6">primary d</p>
        </div>
        <div>
          <p className="bg-secondary p-6">secondary</p>
          <p className="bg-secondary-light p-6">secondary l</p>
          <p className="bg-secondary-dark p-6">secondary d</p>
        </div>
        <div>
          <p className="bg-accent p-6">accent </p>
          <p className="bg-accent-light p-6">accent l</p>
          <p className="bg-accent-dark p-6">accent d</p>
        </div>
      </div>
    </div>
  );
};

export default UiComponents;
