import axiosInstance from './axiosConfig';

// GET Request
export const get = async (endpoint, params = {}) => {
    try {
        const response = await axiosInstance.get(endpoint, { params });
        return response.data;
    } catch (error) {
        console.error('Error fetching data', error);
        throw error;
    }
};

// POST Request
export const  post = async (endpoint, data) => {
    try {
        const response = await axiosInstance.post(endpoint, data);
        return response.data;
    } catch (error) {
        console.error('Error posting data', error);
        return error
    }
};

// PUT Request
export const put = async (endpoint, data) => {
    try {
        const response = await axiosInstance.put(endpoint, data);
        return response.data;
    } catch (error) {
        console.error('Error updating data', error);
        throw error;
    }
};

// DELETE Request
export const del = async (endpoint) => {
    try {
        const response = await axiosInstance.delete(endpoint);
        return response.data;
    } catch (error) {
        console.error('Error deleting data', error);
        throw error;
    }
};
