const getCssVariableValue = (variableName: string) => {
  return getComputedStyle(document.documentElement).getPropertyValue(
    variableName,
  );
};

const createMuiThemeWithColors = () => ({
  palette: {
    primary: {
      main: getCssVariableValue("--color-primary"),
      light: getCssVariableValue("--color-primary-light"),
      dark: getCssVariableValue("--color-primary-dark"),
    },
    secondary: {
      main: getCssVariableValue("--color-secondary"),
      light: getCssVariableValue("--color-secondary-light"),
      dark: getCssVariableValue("--color-secondary-dark"),
    },
    accent: {
      main: getCssVariableValue("--color-accent"),
      light: getCssVariableValue("--color-accent-light"),
      dark: getCssVariableValue("--color-accent-dark"),
    },
    palette: {
      grey: {
        "50": getCssVariableValue("--color-grey-50"),
        "100": getCssVariableValue("--color-grey-100"),
        "200": getCssVariableValue("--color-grey-200"),
        "300": getCssVariableValue("--color-grey-300"),
        "400": getCssVariableValue("--color-grey-400"),
        "500": getCssVariableValue("--color-grey-500"),
        "600": getCssVariableValue("--color-grey-600"),
        "700": getCssVariableValue("--color-grey-700"),
        "800": getCssVariableValue("--color-grey-800"),
        "900": getCssVariableValue("--color-grey-900"),
        A100: getCssVariableValue("--color-grey-A100"),
        A200: getCssVariableValue("--color-grey-A200"),
        A400: getCssVariableValue("--color-grey-A400"),
        A700: getCssVariableValue("--color-grey-A700"),
      },
      text: {
        primary: getCssVariableValue("--color-text-primary"),
        secondary: getCssVariableValue("--color-text-secondary"),
        disabled: getCssVariableValue("--color-text-disabled"),
      },
      divider: getCssVariableValue("--color-divider"),
      background: {
        paper: getCssVariableValue("--color-background-paper"),
        default: getCssVariableValue("--color-background-default"),
      },
    },
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          // border: '1px solid #e2e2e2',
          // outline: "none !important",
          borderRadius: '8px',
          // color: '#14191F',
          fontWeight: 400,
          fontSize: '16px',
          lineHeight: '24px',
          "&.Mui-focused > .MuiOutlinedInput-notchedOutline": {
            // outline: "none",
            borderColor: "#A1A3A5",
            borderWidth: '1px',
          },
          "& > .MuiOutlinedInput-input": {
            padding: '13px 16px',
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: '#14191F',
          fontSize: '14px',
        }
      }
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          color: '#6E7175',
          fontSize: '13px',
          marginLeft: '0'
        }
      }
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&:nth-of-type(even)': {
            backgroundColor: getCssVariableValue("--color-primary"), // Change to your desired color for even rows
          },
          '&:nth-of-type(odd)': {
            backgroundColor: getCssVariableValue("--color-grey-200"), // Change to your desired color for odd rows
          },
        },
      },
    },
    MuiTableCell:{
      styleOverrides: {
        head: {
          color: getCssVariableValue("--color-grey-600"),
          backgroundColor: getCssVariableValue("--color-primary"),
          fontWeight: "500"
        },
        root:{
          fontWeight: "500"
        }
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
          boxShadow: "0 0 11px 0 rgba(0, 0, 0, 0.02)"

        }
      }
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.05)', // Change the box shadow
          borderRadius: '8px', // Change the border radius
        },
      },
    },
  },
});

export const getTheme = () => createMuiThemeWithColors();
