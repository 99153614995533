import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from 'yup';
import {InputAdornment, TextField} from "@mui/material";
import CustomLabel from "../../components/CustomLabel";
import eye from "../../assets/icons/eye.svg";
import eyeOff from "../../assets/icons/eye-off.svg";
import success from "../../assets/icons/ForgotSuccess.svg";
import error from "../../assets/icons/ForgotError.svg";
import CustomModal from "../../components/CustomModal";
import {get, post} from "../../config/apiService";
import {useNavigate} from "react-router-dom";
import { jwtDecode } from "jwt-decode";

const Login: React.FC = () => {
  const [showPassword, setShowPassword] = useState(false);
  const emailInputRef = React.useRef<HTMLInputElement>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [forgotId, setForgotId] = useState<string>('');
  const [forgotState, setForgotState] = useState<number>(0);
  const [errorModal, setErrorModal] = useState<boolean>(false);
  const [forgotErrorModal, setForgotErrorModal] = useState<boolean>(false);

  const navigate = useNavigate()
  const handleOpen = () => {
    setForgotState(0);
    setIsModalOpen(true);
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (emailInputRef.current) {
      emailInputRef.current.focus();
    }
  }, []);

  const handleKeyPress = (event: React.KeyboardEvent<HTMLFormElement>) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      formik.handleSubmit();
    }
  };

  const handleTogglePassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().required('Required'),
      password: Yup.string().required('Required'),
    }),
    onSubmit: async (values) => {
      try {
        const response = await post('/auth/login',  {
            username: values.email,
            password: values.password,
          }
        );
        if (response) {
          localStorage.setItem("accessToken", response.data.access_token);
          localStorage.setItem("refreshToken", response.data.refresh_token);
          const decodedToken: any = jwtDecode(response.data.access_token); // Decode the token
          const merchantId = decodedToken.merchantId;
          const subName = decodedToken.sub
          const isAdmin = response.data.roles.includes("ROLE_ADMIN")
          localStorage.setItem("isAdmin", isAdmin)
          localStorage.setItem('username', subName)
          localStorage.setItem("merchantId", merchantId);
          navigate('/')
          console.log('Login successful:', response);
        } else {
          console.error('Login failed');
        }
      } catch (error) {
        setErrorModal(true)
        console.error('An error occurred:', error);
      }
    },
  });

  const handleForgotCheckSubmit = async () => {
    try {
      const data = await get(`/users/find-by-username?username=${forgotId}`)
      const user = data.data
      if (user && user.role !== 'USER') {
        setForgotState(1);
      } else {
        setForgotState(2);
      }
      setForgotId('');
      console.log('submitted forgot check', forgotId);
    } catch (e){
      setForgotId('');
      setForgotErrorModal(true)
    }
  };

  return (
      <div className="flex justify-center items-center mt-20">
        <div className="w-[578px] rounded-xl shadow-card bg-primary p-[40px_44px]">
          <h4 className="text-[26px] font-medium mb-[28px]">Xoş gəlmisiniz!</h4>
          <form onSubmit={formik.handleSubmit} onKeyPress={handleKeyPress}>
            <div className="mb-4">
              <CustomLabel label={'ID'} required/>
              <TextField
                  fullWidth
                  id="email"
                  name="email"
                  placeholder="ID-ni daxil et"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  inputRef={emailInputRef}
              />
            </div>
            <div className="mb-4">
              <CustomLabel label={'Şifrə'} required/>
              <TextField
                  fullWidth
                  id="password"
                  name="password"
                  placeholder="Şifrəni daxil et"
                  type={showPassword ? 'text' : 'password'}
                  InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                          <div onClick={handleTogglePassword} className="btn">
                            {showPassword ? <img src={eyeOff} alt="eye-off"/> : <img src={eye} alt="eye"/>}
                          </div>
                        </InputAdornment>
                    ),
                  }}
                  value={formik.values.password}
                  onChange={formik.handleChange}
              />
            </div>
            <div className="flex justify-end">
              <span onClick={handleOpen} className="text-[#0072CE] cursor-pointer">Şifrəni unutmuşam</span>
            </div>
            <button
                disabled={!formik.isValid || !formik.dirty}
                type="submit"
                className="btn btn-primary btn-m btn-full mt-9"
            >
              Davam et
            </button>
          </form>
        </div>

        <CustomModal title={forgotState === 0 ? 'Şifrəni unutmuşam' : ''} open={isModalOpen} width="530px" handleClose={handleClose}>
          {forgotState === 0 && (
              <div>
                <div className="mb-4 mt-10">
                  <CustomLabel label={'ID'} required/>
                  <TextField
                      fullWidth
                      id="id"
                      name="id"
                      placeholder="ID-ni daxil et"
                      value={forgotId}
                      onChange={(event: any) => setForgotId(event.target.value)}
                  />
                </div>
                <button onClick={handleForgotCheckSubmit} disabled={!forgotId} type="button"
                        className="btn btn-primary btn-m btn-full mt-10">
                  Davam et
                </button>
              </div>
          )}
          {forgotState === 1 && (
              <div>
                <div className="mb-4 mt-10 text-center">
                  <img className="mx-auto" src={success} alt="forgot_success"/>
                  <h5 className="text-xl font-medium text-[#000000] mt-8">Zəhmət olmasa bankla əlaqə saxlayın!</h5>
                  <p className="text-text-disabled">Verilən şifrəni daxil etdikdən sonra, yeni şifrə təyin edə biləcəksiniz</p>
                </div>
                <button onClick={handleClose} type="button" className="btn btn-primary btn-m btn-full mt-[120px]">
                  Giriş səhifesinə keç
                </button>
              </div>
          )}
          {forgotState === 2 && (
              <div>
                <div className="mb-4 mt-10 text-center">
                  <img className="mx-auto" src={error} alt="forgot_success"/>
                  <h5 className="text-xl font-medium text-[#000000] mt-8">Şifrənin bərpası üçün adminə müraciət et</h5>
                  <p className="text-text-disabled">Zəhmət olmasa şifrənin bərpası üçün admin ilə əlaqə saxlayın.</p>
                </div>
                <button onClick={handleClose} type="button" className="btn btn-primary btn-m btn-full mt-[120px]">Bağla</button>
              </div>
          )}
        </CustomModal>

        <CustomModal title="" width="534px" handleClose={() => setErrorModal(false)} open={errorModal}>
          <div>
            <div className="mb-4 mt-10 text-center">
              <img className="mx-auto" src={error} alt="forgot_success"/>
              <h5 className="text-xl font-medium text-[#000000] mt-8">İstifadəçi adı və ya şifrə yalnışdır!</h5>
              <p className="text-text-disabled">Zəhmət olmasa yenidən yoxlayın</p>
            </div>
            <button onClick={() => setErrorModal(false)} type="button"
                    className="btn btn-primary btn-m btn-full mt-[120px]">Bağla
            </button>
          </div>
        </CustomModal>
        <CustomModal title="" width="534px" handleClose={() => setForgotErrorModal(false)} open={forgotErrorModal}>
          <div>
            <div className="mb-4 mt-10 text-center">
              <img className="mx-auto" src={error} alt="forgot_success"/>
              <h5 className="text-xl font-medium text-[#000000] mt-8">Bu ID-li istifadəçi mövcud deyil.!</h5>
            </div>
            <button onClick={() => setForgotErrorModal(false)} type="button"
                    className="btn btn-primary btn-m btn-full mt-[120px]">Bağla
            </button>
          </div>
        </CustomModal>
      </div>
  );
};

export default Login;
