import React, { useState } from "react";
import SearchCustomer from "../../components/SearchCustomer";
import CustomizedTables from "../../components/CustomTable";
import CustomModal from "../../components/CustomModal";
import CustomLabel from "../../components/CustomLabel";
import {TextField} from "@mui/material";
import Success from "../../assets/icons/ForgotSuccess.svg"
import error from "../../assets/icons/error.svg";
import {useFormik} from "formik"
import * as Yup from "yup";
import Info from "../../assets/icons/Info.svg";

export interface Header {
    label?: string;
    field: string;
    align?: 'left' | 'center' | 'right' | 'justify' | 'inherit';
}

const headers: Header[] = [
    { label: 'Mobil nömrə', field: 'phone' },
    { label: 'Unikal kod', field: 'uuid', align: 'right' },
    { label: 'Müddət / Məbləğ', field: 'date', align: 'right' },
    { label: 'Tarix (g)', field: 'dateTime', align: 'right' },
    { label: '', field: 'action', align: 'right' },
];

const Refund: React.FC = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedUuid, setSelectedUuid] = useState<number | null>(null);
    const [success, setSuccess] = useState<boolean>(false)
    const [notFound, setNotFound] = useState<boolean>(false)
    const formik = useFormik({
        initialValues: {
            price: '',
            reason: '',
        },
        validationSchema: Yup.object({
            price: Yup.string().required('Required'),
        }),
        onSubmit: values => {
            console.log('11111', values)
            setSuccess(true)
        },
    });
    const handleOpen = (uuid: number) => {
        setSelectedUuid(uuid);
        setIsModalOpen(true);
    };

    const handleClose = () => {
        setIsModalOpen(false);
        setSelectedUuid(null);
    };

    const rows = [
        {
            phone: '+994 50 450 86 97',
            uuid: 2794926746,
            date: "18 ay / 4000.00 ₼ ",
            dateTime: "21.09.2023",
            action: <button className="btn btn-secondary px-[16px] py-[10px] rounded-[6px] mx-auto" onClick={() => handleOpen(2794926746)}>Geri qaytar</button>,
        },
        {
            phone: '+994 50 450 86 97',
            uuid: 2794926746,
            date: "18 ay / 4000.00 ₼ ",
            dateTime: "21.09.2023",
            action: <button className="btn btn-secondary px-[16px] py-[10px] rounded-[6px] mx-auto" onClick={() => handleOpen(2794926746)}>Geri qaytar</button>,
        },
        {
            phone: '+994 50 450 86 97',
            uuid: 2794926746,
            date: "18 ay / 4000.00 ₼ ",
            dateTime: "21.09.2023",
            action: <button className="btn btn-secondary px-[16px] py-[10px] rounded-[6px] mx-auto" onClick={() => handleOpen(2794926746)}>Geri qaytar</button>,
        },
        {
            phone: '+994 50 450 86 97',
            uuid: 2794926746,
            date: "18 ay / 4000.00 ₼ ",
            dateTime: "21.09.2023",
            action: <button className="btn btn-secondary px-[16px] py-[10px] rounded-[6px] mx-auto" onClick={() => handleOpen(2794926746)}>Geri qaytar</button>,
        },
        {
            phone: '+994 50 450 86 97',
            uuid: 2794926746,
            date: "18 ay / 4000.00 ₼ ",
            dateTime: "21.09.2023",
            action: <button className="btn btn-secondary px-[16px] py-[10px] rounded-[6px] mx-auto" onClick={() => handleOpen(2794926746)}>Geri qaytar</button>,
        },
    ];

    return (
        <>
            <h4 className="mb-5 text-text-primary text-2xl font-medium">Geri qaytarılma</h4>
            <SearchCustomer />
            <CustomizedTables name="Dessert Table" headers={headers} rows={rows} />

            <CustomModal title="Geri qaytarılma" open={isModalOpen} width="530px" handleClose={handleClose}>
                {success ? <>
                    <div>
                        <div className="mb-4 mt-10 text-center">
                            <img className="mx-auto" src={Success} alt="forgot_success"/>
                            <h5 className="text-xl font-medium text-[#000000] mt-8">Geri qaytarıldı!</h5>
                            <p className="text-text-disabled">Məhsul uğurla geri qaytarıldı.</p>
                        </div>
                        <button onClick={handleClose} type="button"
                                className="btn btn-primary btn-m btn-full mt-[120px]">Bağla
                        </button>
                    </div>
                </> : (
                    <form onSubmit={formik.handleSubmit} >
                        <div className="">
                            <div className="mb-4 w-full mt-10">
                                <CustomLabel label={'Geri qaytarılacaq məbləğ'} required/>
                                <TextField
                                    fullWidth
                                    id="price"
                                    type="number"
                                    name="price"
                                    placeholder="Geri qaytarılacaq məbləği daxil et"
                                    value={formik.values.price}
                                    error={notFound}
                                    onChange={formik.handleChange}
                                />
                            </div>
                            <div className="mb-4 w-full">
                                <CustomLabel label={'Qaytarılma səbəbi'} required/>
                                <TextField
                                    fullWidth
                                    id="reason"
                                    name="reason"
                                    placeholder="Qaytarılma səbəbini daxil et"
                                    value={formik.values.reason}
                                    onChange={formik.handleChange}
                                    error={notFound}
                                />
                                <div className="mt-7 rounded-lg bg-grey-200 flex justify-between items-center px-6 py-5">
                                    <div className="flex gap-2">
                                        <img src={Info} alt=""/>
                                        <span className="text-lg text-text-disabled">Alış məbləği</span>
                                    </div>
                                    <span>6000 ₼</span>
                                </div>
                            </div>
                            <button  disabled={!formik.isValid || !formik.dirty} type="submit"
                                     className="btn btn-secondary btn-s w-full mt-[30px]">Təsdiq et
                            </button>
                        </div>
                        {notFound && (
                            <p className="text-[#FF5F57] flex items-center gap-3">
                                <img src={error}/>
                                <h5 className="text-xl font-medium text-[#000000] mt-8">Uğursuz əməliyyat</h5>
                                <p className="text-text-disabled">Məhsul geri qaytarılmadı.</p>
                            </p>
                        )}
                    </form>
                )}
            </CustomModal>
        </>
    );
};

export default Refund;
