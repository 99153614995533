import React, { useState } from "react";
import CustomizedTables from "../../components/CustomTable";
import { Header } from "../Refund/Refund";
import leftArrow from '../../assets/icons/left-arrow.svg'
import {useNavigate, useParams} from "react-router-dom";

interface IOperation {
    date: string;
    uuid: string;
    amount: string;
    duration: string;
    reverse: string;
    percent: string;
    id: string;
}

const headers: Header[] = [
    { label: 'Tarix və saat', field: 'date' },
    { label: 'Unikal kod', field: 'uuid' },
    { label: 'Məbləğ', field: 'amount' },
    { label: 'Taksit müddəti', field: 'duration' },
    { label: 'Revers məbləği', field: 'reverse' },
    { label: 'Faiz ', field: 'percent' },
    { label: 'ID ', field: 'id' }
];

const UserOperations: React.FC = () => {
    const { id } = useParams();
    const navigation = useNavigate()
    const [operations, setOperations] = useState<IOperation[]>([
        {
            date: '21.10.2023 20:00',
            uuid: '2794926746',
            amount: '4000.00 ₼ ',
            duration: '18 ay',
            reverse: '0.00 ₼',
            percent: '10 %',
            id: 'bayram.yusifov'
        },
        {
            date: '21.10.2023 20:00',
            uuid: '2795626746',
            amount: '4000.00 ₼ ',
            duration: '14 ay',
            reverse: '0.00 ₼',
            percent: '5 %',
            id: 'bayram.yusifov'
        },
        {
            date: '21.10.2023 20:00',
            uuid: '2794926746',
            amount: '4000.00 ₼ ',
            duration: '18 ay',
            reverse: '0.00 ₼',
            percent: '11 %',
            id: 'bayram.yusifov'
        },
        {
            date: '21.10.2023 20:00',
            uuid: '2794926746',
            amount: '4000.00 ₼ ',
            duration: '6 ay',
            reverse: '0.00 ₼',
            percent: '3 %',
            id: 'bayram.yusifov'
        }
    ]);

    return (
        <>
            <h4 className="mb-5 text-text-primary text-2xl font-medium flex gap-2 items-center">
                <img onClick={() => navigation('/users')} src={leftArrow} alt=""/>
                <span>Son əməliyyatlar</span>
            </h4>
            <CustomizedTables
                headers={headers}
                notFoundComponent={<div>not found</div>}
                rows={operations as any}
            />
        </>
    );
};

export default UserOperations;
