import * as React from "react";
import clsx from "clsx";

interface ITableStatusProps {
    status: string;
    color: string;
}

const TableStatus: React.FC<ITableStatusProps> = ({status, color}) => {
    return (
        <div className="flex justify-start items-center gap-2">
            <span className="w-[12px] h-[12px] inline-block rounded-full" style={{backgroundColor: color}}></span>
            <span>{status}</span>
        </div>
    )
}

export default TableStatus
