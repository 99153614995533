import React from "react";
import {InputLabel} from "@mui/material";

interface InputProps {
    required?: boolean;
    label: string
}

const CustomLabel: React.FC<InputProps> = ({required = false, label}) => {
    return (
        <InputLabel className="mb-2" htmlFor={label}>{label}
            <span className="ms-1 text-[#FF5F57] text-[20px] relative top-[4px]">{required ? '*' : '' }</span>
        </InputLabel>
    );
};

export default CustomLabel;
