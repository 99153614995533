import React, { useState } from "react";
import SearchCustomer from "../../components/SearchCustomer";
import { useFormik } from "formik";
import * as Yup from "yup";
import CustomLabel from "../../components/CustomLabel";
import { TextField } from "@mui/material";
import clsx from "clsx";
import success from "../../assets/icons/ForgotSuccess.svg";
import error from "../../assets/icons/ForgotError.svg";
import loading from "../../assets/icons/Loading.svg";
import Success from "../../assets/icons/installmentSuccess.svg";

import CustomModal from "../../components/CustomModal";
import axios from 'axios';
import {post} from "../../config/apiService";
import {useNavigate} from "react-router-dom";

interface InstallmentMonth {
    id: number;
}

interface InstallmentInfo {
    installmentCount: number;
    interestRate: number;
    productCode: number;
}

const Installment: React.FC = () => {
    const navigate = useNavigate()
    const [installmentState, setInstallmentState] = useState<number>(0);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [monthlyPayment, setMonthlyPayment] = useState<string | number>('');
    const [selectedMonth, setSelectedMonth] = useState<number | null>(null);
    const [customerData, setCustomerData] = useState<any>(null);
    const [creditAmountUsed, setCreditAmountUsed] = useState<number>(0);
    const [personalFundUsed, setPersonalFundUsed] = useState<number>(0);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [modalMessage, setModalMessage] = useState<string>('')
    const handleClose = () => {
        setIsModalOpen(false);
    };

    const [installmentMonths, setInstallmentMonths] = useState<InstallmentMonth[]>([]);

    const formik = useFormik({
        initialValues: {
            price: '',
            name: '',
        },
        validationSchema: Yup.object({
            price: Yup.string().required('Required'),
        }),
        onSubmit: values => {
            console.log('Form submitted:', values);
        },
    });


    const calculateMonthlyPayment = (price: string, monthId: number) => {
        if (!price || !monthId) return;

        let adjustedPrice = Number(price);
        let personalUsed = 0;




        if (customerData?.personalFundAmount > 0) {
            personalUsed = Math.min(adjustedPrice, customerData.personalFundAmount);
            adjustedPrice -= personalUsed;
        }

        setPersonalFundUsed(personalUsed);
        setCreditAmountUsed(adjustedPrice);

        const monthlyPayment = adjustedPrice / monthId;
        setMonthlyPayment(monthlyPayment);
    };


    const handlePriceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;

        // Regular expression to allow only numbers
        const regex = /^[0-9]*$/;

        // Check if the input value matches the regex pattern
        if (!regex.test(value)) {
            return; // Do nothing if the input is not valid
        }

        formik.handleChange(event);
        const totalAvailable = customerData?.remainingLimitAmount + customerData?.personalFundAmount;
        if(parseFloat(value) < customerData.minTransactionAmount){
            setErrorMessage('Bu məbləği yaza bilməzsiniz. Minimum limitdən azdır.');
            return;
        }
        if (parseFloat(value) > totalAvailable) {
            setErrorMessage('Bu məbləği yaza bilməzsiniz. Toplam limitdən çoxdur.');
            return;
        } else {
            setErrorMessage(null);
        }

        if (selectedMonth) {
            calculateMonthlyPayment(value, selectedMonth);
        }
    };


    const handleSelectMonth = (monthId: number) => {
        setSelectedMonth(monthId);
        calculateMonthlyPayment(formik.values.price, monthId);
    };

    const handleSendRequest = async (): Promise<void> => {
        if (!customerData || !selectedMonth || !monthlyPayment) return;

        const requestData = {
            merchantId: customerData.merchantId,
            customerNo: customerData.customerNo,
            monthlyPayment: Number(monthlyPayment),
            cardId: customerData.cardId,
            loanId: customerData.loanId,
            transactionAmount: formik.values.price,
            installmentCount: selectedMonth
        };

        try {
            const response = await post('installment/pre/divide-installment', requestData);
            if (response.success) {
                setInstallmentState(0); // Success modal
            }
            else{
                setInstallmentState(2); // Error modal
                setModalMessage(response.message)
            }
        } catch (error) {
            setInstallmentState(2); // Error modal
        } finally {
            setIsModalOpen(true);
        }
    };

    const handleCustomerData = (data: any) => {
        if (data?.customerLoanInfo[0].remainingLimitAmount > 0) {
            setCustomerData(data.customerLoanInfo[0]);

            formik.setFieldValue('price',  '');
            formik.setFieldValue('name', '');
            setPersonalFundUsed(0);
            setCreditAmountUsed(0);
            setMonthlyPayment(0)

            const installmentOptions = data.customerLoanInfo[0].installmentInfo.map((installment: InstallmentInfo) => ({
                id: installment.installmentCount,
            }));
            setInstallmentMonths(installmentOptions);
        } else {
            console.log("Limit bulunamadı, işlem yapılamaz.");
        }
    };

    return (
        <>
            <h4 className="mb-5 text-text-primary text-2xl font-medium">Taksitə bölmə</h4>
            <SearchCustomer onResult={handleCustomerData} />

            {customerData && (
                <div className="p-8 bg-primary rounded-lg shadow-card mt-5">
                    <h4 className="mb-5 text-text-primary text-xl font-medium">Əməliyyat detalları</h4>
                    <div>
                        <form onSubmit={formik.handleSubmit}>
                            <div className="flex justify-start gap-6">
                                <div className="mb-4 w-full max-w-[312px]">
                                    <CustomLabel label={'Alış məbləği'} required />
                                    <TextField
                                        fullWidth
                                        id="price"
                                        name="price"
                                        type="text" // Change from "number" to "text" to apply regex validation
                                        placeholder="Alış məbləğini daxil et"
                                        value={formik.values.price}
                                        onChange={handlePriceChange} // Use the updated handler
                                    />
                                    {errorMessage && (
                                        <div className="mt-3 text-red-500 font-bold">
                                            {errorMessage}
                                        </div>
                                    )}
                                </div>
                                <div className="mb-4 w-full max-w-[312px]">
                                    <CustomLabel label={'Məhsul'} />
                                    <TextField
                                        fullWidth
                                        id="name"
                                        name="name"
                                        type="text"
                                        placeholder="Məhsul adını daxil et"
                                        value={formik.values.name}
                                        onChange={formik.handleChange}
                                    />
                                </div>
                            </div>
                        </form>
                        <div className="mb-4 w-full max-w-[648px] mt-3">
                            <CustomLabel label={'Taksit sayını seç'} required />
                            <div className="grid grid-rows-2 grid-cols-4 gap-2">
                                {installmentMonths.map((month: InstallmentMonth) => (
                                    <div
                                        key={month.id}
                                        onClick={() => handleSelectMonth(month.id)}
                                        className={clsx(
                                            'border-divider border text-center rounded-lg p-4 cursor-pointer',
                                            {
                                                'bg-[#e8f0e4]': month.id === selectedMonth,
                                                'border-[#6CC24A]': month.id === selectedMonth
                                            }
                                        )}
                                    >
                                        <span className="font-medium text-[17px] text-[#000000]">{month.id} ay</span>
                                    </div>
                                ))}
                            </div>

                            <div className="mt-7 rounded-lg bg-grey-200 px-6 py-5">
                                <div className="flex justify-between items-center px-2 py-3">
                                    <span className="text-lg text-text-disabled">Aylıq ödəniş</span>
                                    <span>{Number(monthlyPayment).toFixed(2)} ₼</span>
                                </div>
                                <div className="flex justify-between items-center px-2 py-3 border-divider border border-x-0">
                                    <span className="text-lg text-text-disabled">Kredit vəsaitindən istifadə</span>
                                    <span>{Number(creditAmountUsed).toFixed(2)} ₼</span>
                                </div>
                                <div className="flex justify-between items-center px-2 py-3">
                                    <span className="text-lg text-text-disabled">Şəxsi vəsaitdən istifadə</span>
                                    <span>{Number(personalFundUsed).toFixed(2)} ₼</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {customerData && <button onClick={handleSendRequest} type="button"
                                     disabled={!formik.isValid || !formik.dirty || !monthlyPayment} className="btn btn-primary btn-m w-[200px] ms-auto mt-4">Təklif göndər</button>}

            <CustomModal title="" open={isModalOpen} width="530px" handleClose={handleClose}>
                {installmentState === 0 && (
                    <div>
                        <div className="mb-4 mt-10 text-center">
                            <img className="mx-auto" src={Success} alt="Success"/>
                            <h5 className="text-xl font-medium text-[#000000] mt-8">Müştəriyə təklif göndərildi</h5>
                            <p className="text-text-disabled">Son əməliyyatlara keçərək statuslar ilə tanış ol.</p>
                            <div className="flex justify-between items-center gap-4">
                                <button onClick={() => navigate('/operations')} type="button"
                                        className="btn btn-primary btn-m btn-full mt-[50px] !h-[54px]">Son əməliyyatlara keç
                                </button>
                            </div>
                            {/*<div className="mt-10 rounded-lg p-5 bg-grey-200">*/}
                            {/*    <div className="flex justify-between items-center border-b border-divider pb-3">*/}
                            {/*        <span className="text-text-disabled">Mobil nömrə</span>*/}
                            {/*        <span className="text-[#000000] font-medium">{customerData?.mobileNumber || ''}</span>*/}
                            {/*    </div>*/}
                            {/*    <div className="flex justify-between items-center border-b border-divider pb-3 pt-3">*/}
                            {/*        <span className="text-text-disabled">Doğum tarixi</span>*/}
                            {/*        <span className="text-[#000000] font-medium">{customerData?.birthDate || ''}</span>*/}
                            {/*    </div>*/}
                            {/*    <div className="flex justify-between items-center border-b border-divider pb-3 pt-3">*/}
                            {/*        <span className="text-text-disabled">Alış məbləği / Aylıq ödəniş</span>*/}
                            {/*        <span className="text-[#000000] font-medium">{formik.values.price}₼ / {Number(monthlyPayment).toFixed(2)}₼</span>*/}
                            {/*    </div>*/}
                            {/*    <div className="flex justify-between items-center border-b border-divider pt-3">*/}
                            {/*        <span className="text-text-disabled">Taksit müddəti</span>*/}
                            {/*        <span className="text-[#000000] font-medium">{selectedMonth} ay</span>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                )}
                {installmentState === 1 && (
                    <div>
                        <div className="mb-4 mt-10 text-center">
                            <img className="mx-auto" src={success} alt="forgot_success"/>
                            <h5 className="text-xl font-medium text-[#000000] mt-8">Hissə hissə ödəniş
                                rəsmiləşdirildi!</h5>
                            <p className="text-text-disabled">Hesabat bölməsinə alış əlavə olundu. </p>
                        </div>
                        <button onClick={handleClose} type="button" className="btn btn-primary btn-m btn-full mt-[120px]">Bağla</button>
                    </div>
                )}
                {installmentState === 2 && (
                    <div>
                        <div className="mb-4 mt-10 text-center">
                            <img className="mx-auto" src={error} alt="forgot_success" />
                            <h5 className="text-xl font-medium text-[#000000] mt-8">{modalMessage ||'Uğursuz əməliyyat' }</h5>
                            <p className="text-text-disabled">Zəhmət olmasa məlumatları yenidən daxil et.</p>
                        </div>
                        <button onClick={handleClose} type="button" className="btn btn-primary btn-m btn-full mt-[120px]">Bağla</button>
                    </div>
                )}
            </CustomModal>
        </>
    );
};

export default Installment;
