import React, {ReactNode, useState} from "react";
import {Fade, Modal} from "@mui/material";
import close from "../assets/icons/close.svg";

interface InputProps {
    title: string;
    width: string;
    children: ReactNode;
    handleClose: () => void;
    open: boolean;
    isHeader?: boolean;
    hideClose?: boolean;
}


const CustomModal: React.FC<InputProps> = ({title, width, open, handleClose, children, hideClose = false, isHeader= true}) => {

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
            closeAfterTransition
        >
            <Fade in={open}>
                <div className={`mx-auto mt-10 bg-primary rounded-lg  ${isHeader ? "px-8 py-9" : ""} `} style={{width}}>
                    {isHeader && (
                        <div className="flex justify-between items-center">
                            <h2 className="text-xl font-medium">{title}</h2>
                            {!hideClose && (
                                <button className="btn p-4 bg-grey-200" onClick={handleClose}>
                                    <img src={close} alt="close_icon"/>
                                </button>
                            ) }
                        </div>
                    )}
                    <div>
                        {children}
                    </div>
                </div>
            </Fade>
        </Modal>
    );
};

export default CustomModal
