import * as React from 'react';
import {useState} from "react";
import { Calendar,  DateObject } from 'react-multi-date-picker';
import CustomModal from "./CustomModal";
import dayjs from "dayjs";
import clsx from "clsx";

export default function SingleInputDateRangePicker({isModalOpen , handleCloseModal, setSelectedDateRange}: any) {
    const [dates, setDates] = useState([])
    const [date, setDate] = useState<string>("")
    const [startDate, setStartDate] = useState<string>("")
    const [endDate, setEndDate] = useState<string>("")
    const [mode, setMode] = useState('range');

    const handleClose = () => {
        handleCloseModal();
    };

    console.log(isModalOpen)

    const handleModeChange = (newMode: string) => {
        setMode(newMode);
        setDates([]); // Reset the selected dates when the mode changes
    };

    const getTabClass = (tabMode: string) => {
        return `w-full cursor-pointer  py-[10px] pl-[30px] ${mode === tabMode ? 'bg-[#6CC24A1A]' : ''}`;
    };

    const formatDates = (dateObjects: DateObject[]) => {
        if (dateObjects.length === 0) return '';

        if (mode === 'range' && dateObjects.length === 2) {
            const startDate = dayjs(dateObjects[0].toDate()).format('DD.MM.YYYY');
            const endDate = dayjs(dateObjects[1].toDate()).format('DD.MM.YYYY');
            setStartDate(startDate)
            setEndDate(endDate)
            setDate(`${startDate} - ${endDate}`)
            const formattedDateRange = `${startDate} - ${endDate}`;
            setSelectedDateRange(formattedDateRange);
            return formattedDateRange;
        }

        if (mode === 'month' && dateObjects.length === 2) {
            const startMonth = dayjs(dateObjects[0].toDate()).format('MM.YYYY');
            const endMonth = dayjs(dateObjects[1].toDate()).format('MM.YYYY');
            setStartDate(startMonth)
            setEndDate(endMonth)
            setDate(`${startMonth} - ${endMonth}`)
            const formattedDateRange = `${startMonth} - ${endMonth}`;
            setSelectedDateRange(formattedDateRange);
            return formattedDateRange
        }

        if (mode === 'year' && dateObjects.length === 2) {
            const startYear = dayjs(dateObjects[0].toDate()).format('YYYY');
            const endYear = dayjs(dateObjects[1].toDate()).format('YYYY');
            setStartDate(startYear)
            setEndDate(endYear)
            setDate(`${startYear} - ${endYear}`)
            const formattedDateRange = `${startYear} - ${endYear}`;
            setSelectedDateRange(formattedDateRange);
            return formattedDateRange
        }
        setDate(`${dayjs(dateObjects[0].toDate()).format('DD.MM.YYYY')}`)
        return dayjs(dateObjects[0].toDate()).format('DD.MM.YYYY');
    };


    return (
        <div className="mt-200px">
            <CustomModal title="" width={'530px'} handleClose={handleCloseModal} open={isModalOpen} isHeader={false}>
                <>
                <div className="flex">
                    <div className="w-[160px] border-r border-b border-[#EAEAEA] py-7">
                        <ul className="w-full">
                            <li className={getTabClass('range')} onClick={() => handleModeChange('range')}>Gün aralığı</li>
                            <li className={getTabClass('month')} onClick={() => handleModeChange('month')}>Ay aralığı</li>
                            <li className={getTabClass('year')} onClick={() => handleModeChange('year')}>İl</li>

                        </ul>
                    </div>
                    <Calendar
                        range
                        onlyMonthPicker={mode === 'month'}
                        onlyYearPicker={mode === 'year'}
                        className="!shadow-none #6CC24A"
                        value={dates}
                        onChange={(dateObjects: any) => {
                            console.log(dateObjects)
                            console.log(formatDates(dateObjects));
                        }}
                    />

                </div>
                <div className="flex justify-between p-5">
                    <div className="flex items-center gap-4">
                        {startDate && (
                            <div
                                className={clsx(
                                    'border-divider border text-center rounded-lg px-4 py-2 cursor-pointer',
                                )}
                            >
                                {startDate && <span className="text-[14px] text-[#000000]">{startDate}</span>}

                            </div>
                        )}

                        {startDate && endDate && <span className="text-[14px] text-[#CCCCCC]">---</span>}
                        {endDate && (
                            <div
                                className={clsx(
                                    'border-divider border text-center rounded-lg px-4 py-2 cursor-pointer',
                                )}
                            >
                                {endDate && <span className=" text-[14px] text-[#000000]">{endDate}</span>}

                            </div>
                        )
                        }

                    </div>
                    {startDate && endDate &&(
                    <div className="flex items-center justify-end">

                            <button onClick={handleCloseModal} className="btn btn-secondary px-[16px] py-[10px] rounded-[6px]">
                                Tətbiq et
                            </button>
                    </div>
                    )}
                </div>
                </>

            </CustomModal>
        </div>
    );
}
