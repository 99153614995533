import React, { ReactNode } from "react";
import { Outlet } from "react-router-dom";
import logo from "../logo.svg";

import Header from "./components/Header";
import styles from "./Layout.module.scss";
import Sidebar from "./components/Sidebar";

const Layout: React.FC<any> = () => {
  return (
      <div className="bg-background-paper flex flex-col h-screen">
          <div className="h-2 w-full bg-secondary"></div>
          <div className="grid grid-rows-[auto,1fr] grid-cols-[380px,1fr] min-h-screen">
              {/* Logo */}
              <div className="border-b border-divider border-e row-span-1 col-span-1 flex items-center bg-primary h-[108px] ps-[56px]">
                  <img src={logo} alt="Logo"/>
              </div>
              {/* Header */}
              <div className="border-b border-divider row-span-1 col-start-2 col-end-3 flex items-center justify-between bg-primary p-4 h-[108px]">
                  <Header/>
              </div>
              {/* Sidebar */}
              <div className="border-e border-divider row-start-2 row-end-3 col-span-1 bg-primary p-4">
                  <Sidebar/>
              </div>
              {/* Main Content */}
              <div className="row-start-2 row-end-3 col-start-2 col-end-3 bg-background-paper p-8">
                  <Outlet/>
              </div>
          </div>
      </div>
  );
};

export default Layout;
