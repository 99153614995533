import React, { useState } from "react";
import CustomLabel from "./CustomLabel";
import { TextField } from "@mui/material";
import error from "../assets/icons/error.svg";
import { useFormik } from "formik";
import * as Yup from "yup";
import CustomMaskedInput from "./CustomMaskedInput";
import axios from 'axios';
import {get} from "../config/apiService";
import CustomModal from "./CustomModal";

const SearchCustomer: React.FC<{ onResult?: (data: any) => void }> = ({ onResult }) => {
    const [notFound, setNotFound] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false); // Loading state
    const [errorModal, setErrorModal] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>();

    const formik = useFormik({
        initialValues: {
            birthDate: '',
            number: '',
        },
        validationSchema: Yup.object({
            birthDate: Yup.string()
                .required('Required')
                .matches(/^(0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[0-2])\.(19|20)\d{2}$/, 'Invalid date format'),
            number: Yup.string().required('Required'),
        }),
        onSubmit: async (values) => {
            const merchantId = localStorage.getItem("merchantId");
            setLoading(true);
            const cleanedNumber = values.number.replace(/[^\d]/g, "");
            try {
                const response = await get(`/installment/customer-loan?merchantId=${merchantId}&phone=${cleanedNumber}&birthday=${values.birthDate}`, {
                });
                if(response && !response.success){
                    setErrorModal(true);
                    setErrorMessage(response.message)
                }
                onResult && onResult(response.data);
                setNotFound(false);
            } catch (error) {
                console.error('API Hatası:', error);
                setNotFound(true);
            }

            // Loading'i durdur
            setLoading(false);
        },
    });

    if (loading) {
        return <div>Zəhmət olmasa gözləyin...</div>;
    }

    return (
        <div className="p-8 bg-primary rounded-lg shadow-card">
            <h4 className="mb-5 text-text-primary text-xl font-medium">Müştəri məlumatları</h4>
            <form onSubmit={formik.handleSubmit} >
                <div className="flex justify-start gap-6">
                    <div className="mb-4 w-full max-w-[312px]">
                        <CustomLabel label={'Mobil nömrə'} required/>
                        <TextField
                            fullWidth
                            id="number"
                            name="number"
                            placeholder="Mobil nömrəni daxil et"
                            value={formik.values.number}
                            error={notFound}
                            onChange={formik.handleChange}
                            InputProps={{
                                inputComponent: CustomMaskedInput,
                                inputProps: { mask: "+\\9\\94 (99) 999 99 99", maskChar: " " }
                            }}
                        />
                    </div>
                    <div className="mb-4 w-full max-w-[312px]">
                        <CustomLabel label={'Doğum tarixi'} required/>
                        <TextField
                            fullWidth
                            id="birthDate"
                            name="birthDate"
                            placeholder="Doğum tarixini daxil et"
                            value={formik.values.birthDate}
                            onChange={formik.handleChange}
                            error={notFound}
                            InputProps={{
                                inputComponent: CustomMaskedInput,
                                inputProps: { mask: "99.99.9999", maskChar: "_" }
                            }}
                        />
                    </div>
                    <button  disabled={!formik.isValid || !formik.dirty} type="submit"
                             className="btn btn-secondary btn-s mt-[30px]">Davam et
                    </button>
                </div>
                {notFound && (
                    <p className="text-[#FF5F57] flex items-center gap-3">
                        <img src={error} alt="error-icon"/>
                        <span>Qeyd olunan məlumatlara əsasən istifadəçi tapılmadı</span>
                    </p>
                )}
            </form>
            <CustomModal title="" width="534px" handleClose={() => setErrorModal(false)} open={errorModal}>
                <div>
                    <div className="mb-4 mt-10 text-center">
                        <img className="mx-auto w-[30%]" src={error} alt="forgot_success"/>
                        <h5 className="text-xl font-medium text-[#000000] mt-8">{errorMessage}</h5>
                        <p className="text-text-disabled">Zəhmət olmasa yenidən yoxlayın</p>
                    </div>
                    <button onClick={() => setErrorModal(false)} type="button"
                            className="btn btn-primary btn-m btn-full mt-[50px]">Bağla
                    </button>
                </div>
            </CustomModal>
        </div>
    );
};

export default SearchCustomer;
