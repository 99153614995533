import React from "react";
import logo from "../logo.svg";
import { Outlet } from "react-router-dom";
import phone from "../assets/icons/phone.svg"

const LoginLayout: React.FC<any> = () => {
  return (
    <div className="bg-background-paper flex flex-col">
      <div className="h-2 w-full bg-secondary"></div>
      <div className="h-[108px] container mx-auto flex justify-between items-center">
        <div>
          <img src={logo} alt="Yelo" />
        </div>
        <div className="flex items-center">
            <img src={phone} alt=""/>
            <span className="ms-[10px] mt-1">981</span>
        </div>
      </div>
      <main className="container mx-auto h-screen">
        <Outlet />
      </main>
    </div>
  );
};

export default LoginLayout;
