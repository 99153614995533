import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom"; // Import useLocation for getting query params
import SearchReport from "../../components/SearchReport";
import CustomizedTables from "../../components/CustomTable";
import SingleInputDateRangePicker from "../../components/CustomDatePicker";
import { get } from "../../config/apiService";
import { Button, Pagination } from "@mui/material"; // Import pagination and button from MUI

interface Header {
    label?: string;
    field: string;
    align?: 'left' | 'center' | 'right' | 'justify' | 'inherit';
}

// Enum for status translations
enum StatusEnum {
    "APPROVED" = "Uğurlu əməliyyat",
    "PENDING" = "Təsdiq gözləyir",
    "REJECTED" = "İmtina edilib",
    "EXPIRED" = "Təsdiq vaxtı bitmişdir",
}

// Enum for status colors
const statusColors: { [key in keyof typeof StatusEnum]: any } = {
    "APPROVED": "bg-green-500",   // Green for approved
    "PENDING": "bg-yellow-500",   // Yellow for pending
    "REJECTED": "bg-red-500",     // Red for rejected
    "EXPIRED": "bg-red-500",      // Red for expired
};

const headers: Header[] = [
    { label: 'Tarix və saat', field: 'trnDate' },
    { label: 'Unikal kod', field: 'transhTxFid' },
    { label: 'Məbləğ', field: 'trnAmount' },
    { label: 'Taksit müddəti', field: 'installmentCount' },
    { label: 'Status', field: 'status' },
    { label: 'ID', field: 'cashierUsername' },
];

const Operations: React.FC = () => {
    const [filteredRows, setFilteredRows] = useState<any[]>([]); // State to hold filtered rows
    const [pageNumber, setPageNumber] = useState<number>(1); // State for current page
    const [pageSize, setPageSize] = useState<number>(10); // State for page size
    const [totalRows, setTotalRows] = useState<number>(0); // State for total rows

    const location = useLocation();

    // Extract query parameter from the URL
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get('id'); // Get the id parameter from the URL

    const getData = async () => {
        let url = `/installment/last-operation?pageNumber=${pageNumber}&pageSize=${pageSize}`;

        // Append the id query parameter if it exists
        if (id) {
            url += `&username=${id}`;
        }

        const data = await get(url);

        const updatedRows = data.data.map((row: any) => {
            console.log(StatusEnum[row.status as keyof typeof StatusEnum], row.status)
            const status = row.status as keyof typeof StatusEnum;
            return {
                ...row,
                installmentCount: `${row.installmentCount} ay`,
                trnAmount: `${row.trnAmount} ₼`,
                status: (
                    <div className="flex items-center">
                        <span className={`w-3 h-3 rounded-full mr-2 ${statusColors[status]}`}></span>
                        {StatusEnum[status]}
                    </div>
                ),
            }

        });

        setFilteredRows(updatedRows);
        setTotalRows(data.totalCount); // Assuming your API returns total rows for pagination
    };

    useEffect(() => {
        getData();
    }, [pageNumber, pageSize, id]); // Refetch data when pageNumber, pageSize, or id changes

    const handleRefresh = () => {
        setPageSize(10);
        setPageNumber(1);
        getData(); // Refetch the data when the button is clicked
    };

    const handlePageChange = (event: React.ChangeEvent<unknown>, newPage: number) => {
        setPageNumber(newPage);
    };

    // Function to render status with color circle
    const renderStatus = (status: keyof typeof StatusEnum) => (
        <div className="flex items-center">
            <span
                className={`w-3 h-3 rounded-full mr-2 ${statusColors[status]}`}
            ></span>
            {StatusEnum[status]}
        </div>
    );

    return (
        <>
            <h1 className="mb-[20px] font-medium text-[20px] text-text-primary">Son Əməliyyatlar</h1>
            <div className="flex justify-end mb-5">
                <button className="btn btn-secondary btn-s mt-[30px]" onClick={handleRefresh}>
                    Səhifəni Yenilə
                </button>
            </div>

            {/* Table */}
            <CustomizedTables
                name=""
                headers={headers}
                rows={filteredRows}
            />

            {/* Pagination */}
            <Pagination
                count={Math.ceil(totalRows / pageSize)} // Total number of pages based on totalRows
                page={pageNumber}
                onChange={handlePageChange}
                variant="outlined"
                shape="rounded"
                className="mt-4"
            />
        </>
    );
};

export default Operations;
