import axios from 'axios';

// Base URL for the API
const baseURL = 'https://dev-ms-installment.yelo.az/api/v1';

// Create Axios instance
const axiosInstance = axios.create({
    baseURL,
    timeout: 10000, // 10 seconds timeout
    headers: {
        'Content-Type': 'application/json',
    },
});

// Token storage and refresh logic (assuming you store the token in localStorage)
let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
    failedQueue.forEach(prom => {
        if (token) {
            prom.resolve(token);
        } else {
            prom.reject(error);
        }
    });

    failedQueue = [];
};

const refreshAccessToken = async () => {
    try {
        const refreshToken = localStorage.getItem('refreshToken');
        const response = await axios.post(`${baseURL}/auth/refresh-token`, {
            token: refreshToken,
        });
        const { accessToken } = response.data;

        localStorage.setItem('accessToken', accessToken);
        return accessToken;
    } catch (error) {
        return Promise.reject(error);
    }
};

// Request Interceptor
axiosInstance.interceptors.request.use(
    config => {
        const accessToken = localStorage.getItem('accessToken');
        if (accessToken) {
            config.headers['Authorization'] = `Bearer ${accessToken}`;
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

// Response Interceptor
axiosInstance.interceptors.response.use(
    response => {
        return response;
    },
    async error => {
        const originalRequest = error.config;

        if(error.response && error.response.status === 409){
            localStorage.removeItem('accessToken');
            localStorage.removeItem('refreshToken');
            window.location.href = '/login'; // Redirect to login page
            return Promise.reject(error);
        }

        if (error.response && error.response.status === 401 && !originalRequest._retry) {
            if (!isRefreshing) {
                isRefreshing = true;
                originalRequest._retry = true;

                try {
                    const newAccessToken = await refreshAccessToken();
                    processQueue(null, newAccessToken);

                    return axiosInstance(originalRequest);
                } catch (refreshError) {
                    window.location.href = '/login';
                    processQueue(refreshError, null);
                    return Promise.reject(refreshError);
                } finally {
                    isRefreshing = false;
                }
            }

            return new Promise((resolve, reject) => {
                failedQueue.push({ resolve, reject });
            }).then(token => {
                originalRequest.headers['Authorization'] = 'Bearer ' + token;
                return axiosInstance(originalRequest);
            }).catch(err => {
                return Promise.reject(err);
            });
        }

        return Promise.reject(error);
    }
);

export default axiosInstance;
