import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

const PublicPage: React.FC = () => {
  return (
    <Routes>
      <Route path="asd" element={<p>asdasdasdasda</p>} />
    </Routes>
  );
};

export default PublicPage;
