import React from "react";
import users from '../../../assets/icons/users-large.svg'

const UsersNotFound = () => {
    return (
        <div className="text-center pt-[92px] pb-[100px]">
            <img className="mx-auto" src={users} alt="users"/>
            <h5 className="text-xl font-medium mb-2 mt-7 text-[#000000]">İstifadəçi əlavə olunmayıb</h5>
            <p className="text-text-disabled">İstifadəçi əlavə olunduqca, siyahı şəklində görsənəcək</p>
        </div>
    );
};

export default UsersNotFound
