import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from 'yup';
import { InputAdornment, TextField } from "@mui/material";
import CustomLabel from "../../components/CustomLabel";
import eye from "../../assets/icons/eye.svg"
import eyeOff from "../../assets/icons/eye-off.svg"
import success from "../../assets/icons/ForgotSuccess.svg";
import CustomModal from "../../components/CustomModal";
import {useNavigate} from "react-router-dom";

const ChangePassword: React.FC = () => {
    const navigate = useNavigate()
    const [showPassword, setShowPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showNewPasswordRepeat, setShowNewPasswordRepeat] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const emailInputRef = React.useRef<HTMLInputElement>(null);

    const handleClose = () => {
        navigate('/login')
    };

    useEffect(() => {
        if (emailInputRef.current) {
            emailInputRef.current.focus();
        }
    }, []);

    const handleKeyPress = (event: React.KeyboardEvent<HTMLFormElement>) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault();
            formik.handleSubmit();
        }
    };

    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
            newPassword: '',
            newPasswordRepeat: ''
        },
        validationSchema: Yup.object({
            email: Yup.string().required('Required'),
            password: Yup.string().required('Required'),
            newPassword: Yup.string()
                .required('Required')
                .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d]{8,}$/, 'Must contain at least 1 uppercase letter, 1 lowercase letter, and 1 number'),
            newPasswordRepeat: Yup.string()
                .required('Required')
                .oneOf([Yup.ref('newPassword')], 'Passwords must match')
        }),
        onSubmit: values => {
            console.log('Form submitted:', values);
            setIsModalOpen(true);
        },
    });

    return <div className="flex justify-center items-center mt-20">
        <div className="w-[578px] rounded-xl shadow-card bg-primary p-[40px_44px]">
            <h4 className="text-[26px] font-medium mb-[28px]">Xoş gəlmisiniz!</h4>
            <form onSubmit={formik.handleSubmit} onKeyPress={handleKeyPress}>
                <div className="mb-4">
                    <CustomLabel label={'ID'} required/>
                    <TextField
                        fullWidth
                        id="email"
                        name="email"
                        placeholder="ID-ni daxil et"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        inputRef={emailInputRef}
                    />
                </div>
                <div className="mb-4">
                    <CustomLabel label={'Şifrə'} required/>
                    <TextField
                        fullWidth
                        id="password"
                        name="password"
                        placeholder="Şifrəni daxil et"
                        type={showPassword ? 'text' : 'password'}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <button onClick={() => setShowPassword((prevShowPassword) => !prevShowPassword)} className="btn">
                                        {showPassword ? <img src={eyeOff} alt="eye-off"/> : <img src={eye} alt="eye"/>}
                                    </button>
                                </InputAdornment>
                            ),
                        }}
                        value={formik.values.password}
                        onChange={formik.handleChange}
                    />
                </div>
                <div className="mb-4">
                    <CustomLabel label={'Yeni şifrə'} required/>
                    <TextField
                        fullWidth
                        id="newPassword"
                        name="newPassword"
                        placeholder="Yeni şifrəni daxil et"
                        type={showNewPassword ? 'text' : 'password'}
                        helperText={"Ən azı 1 böyük, 1 kiçik hərf və 1 rəqəm olmalıdır"}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <button onClick={() => setShowNewPassword((pass) => !pass)} className="btn">
                                        {showPassword ? <img src={eyeOff} alt="eye-off"/> : <img src={eye} alt="eye"/>}
                                    </button>
                                </InputAdornment>
                            ),
                        }}
                        value={formik.values.newPassword}
                        onChange={formik.handleChange}
                    />
                </div>

                <div className="mb-4">
                    <CustomLabel label={'Yeni şifrə təsdiqi'} required/>
                    <TextField
                        fullWidth
                        id="newPasswordRepeat"
                        name="newPasswordRepeat"
                        placeholder="Yeni şifrəni təkrar daxil et"
                        type={showNewPasswordRepeat ? 'text' : 'password'}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <button onClick={() => setShowNewPasswordRepeat((pass) => !pass)} className="btn">
                                        {showPassword ? <img src={eyeOff} alt="eye-off"/> : <img src={eye} alt="eye"/>}
                                    </button>
                                </InputAdornment>
                            ),
                        }}
                        value={formik.values.newPasswordRepeat}
                        onChange={formik.handleChange}
                    />
                </div>
                <button disabled={!formik.isValid || !formik.dirty} type="submit"
                        className="btn btn-primary btn-m btn-full mt-9">Davam et
                </button>
            </form>
        </div>
        <CustomModal title="" open={isModalOpen} width="530px" handleClose={handleClose}>
            <div>
                <div className="mb-4 mt-10 text-center">
                    <img className="mx-auto" src={success} alt="forgot_success"/>
                    <h5 className="text-xl font-medium text-[#000000] mt-8">Yeni şifrə uğurla təyin edildi!</h5>
                    <p className="text-text-disabled">Giriş səhifəsinə keçid etdikdən sonra, yeni şifrənizi daxil edərək əməliyyatlarınızı yerinə yetirə bilərsiniz</p>
                </div>
                <button onClick={handleClose} type="button"
                        className="btn btn-primary btn-m btn-full mt-[120px]">Giriş səhifesinə keç
                </button>
            </div>
        </CustomModal>
    </div>;
};

export default ChangePassword;
