import React, { useEffect, useState } from "react";
import SearchReport from "../../components/SearchReport";
import CustomizedTables from "../../components/CustomTable";
import SingleInputDateRangePicker from "../../components/CustomDatePicker";

interface Header {
    label?: string;
    field: string;
    align?: 'left' | 'center' | 'right' | 'justify' | 'inherit';
}

const headers: Header[] = [
    { label: 'Mobil nömrə', field: 'phone' },
    { label: 'Unikal kod', field: 'uuid', align: 'right' },
    { label: 'Müddət / Məbləğ', field: 'date', align: 'right' },
    { label: 'Tarix (g)', field: 'dateTime', align: 'right' },
    { label: '', field: 'action', align: 'right' },
];

const Reports: React.FC = () => {
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [selectedUuid, setSelectedUuid] = useState<number | null>(null);
    const [selectedDateRange, setSelectedDateRange] = useState<string>("");
    const [filteredRows, setFilteredRows] = useState<any[]>([]); // State to hold filtered rows

    const rows = [
        {
            phone: '+994 50 450 86 97',
            uuid: 2794926746,
            date: "18 ay / 4000.00 ₼ ",
            dateTime: "21.09.2023",
        },
        {
            phone: '+994 50 450 86 97',
            uuid: 2794926746,
            date: "18 ay / 4000.00 ₼ ",
            dateTime: "21.09.2023",
        },
        {
            phone: '+994 50 450 86 97',
            uuid: 1234567890,
            date: "12 ay / 3000.00 ₼ ",
            dateTime: "15.08.2023",
        },
        {
            phone: '+994 50 450 86 97',
            uuid: 9876543210,
            date: "6 ay / 1500.00 ₼ ",
            dateTime: "01.07.2023",
        },
        {
            phone: '+994 50 450 86 97',
            uuid: 2794926746,
            date: "18 ay / 4000.00 ₼ ",
            dateTime: "21.09.2023",
        },
    ];

    // Filter rows based on selectedUuid and selectedDateRange
    const filterRows = () => {
        return rows.filter(row => {
            const matchesUuid = selectedUuid
                ? row.uuid.toString().startsWith(selectedUuid.toString()) // Check if UUID starts with selectedUuid
                : true;// Check if UUID matches
            const matchesDateRange = selectedDateRange ? row.dateTime.includes(selectedDateRange) : true; // Check if date range matches

            return matchesUuid && matchesDateRange; // Return true if both conditions match
        });
    };

    useEffect(() => {
        const filteredData = filterRows();
        setFilteredRows(filteredData); // Set filtered rows
        console.log(selectedDateRange, selectedUuid, 'Filtered Rows:', filteredData); // Debug log
    }, [selectedDateRange, selectedUuid]);

    const handleOpen = () => {
        setIsModalOpen(true);
    };

    const handleClose = () => {
        setIsModalOpen(false);
    };

    return (
        <>
            <h1 className="mb-[20px] font-medium text-[20px] text-text-primary">Əməliyyatlar</h1>
            <SearchReport
                handleOpenModal={handleOpen}
                selectedDateRange={selectedDateRange}
                setSelectedUuid={setSelectedUuid}
            />
            <CustomizedTables name="" headers={headers} rows={filteredRows} /> {/* Pass filtered rows to the table */}
            <SingleInputDateRangePicker
                isModalOpen={isModalOpen}
                handleCloseModal={handleClose}
                setSelectedDateRange={setSelectedDateRange}
            />
        </>
    );
};

export default Reports;
