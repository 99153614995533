import React, {ReactNode, useState} from "react";
import {IconButton, Menu, MenuItem} from "@mui/material";
import dotMenu from '../assets/icons/3-dot.svg'

interface IActionMenuProps {
    children: ReactNode
}

const ActionMenu: React.FC<IActionMenuProps> = ({children}) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <IconButton onClick={handleClick}>
                <img className="w-[16px] h-[16px]" src={dotMenu} alt="menu"/>
            </IconButton>
            <div className="">
                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                >
                    {children}
                </Menu>
            </div>
        </div>
    );
};

export default ActionMenu
